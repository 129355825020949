import React, { FC, useState } from "react"
import { graphql } from "gatsby"
import {
  SanityPage,
  SanityTestimonialSection,
  SanityCarouselSection,
  SanityHero,
  SanityBusinessSection,
  SanityPromotion,
  SanityFeaturesSection,
  SanityInfoSection,
  sanity_typename,
} from "@utils/types"
import LangContext from "@contexts/lang"
import HeroSection from "../components/homePage/HeroSection"
import CarouselSection from "../components/common/CarouselSection"
import FeaturesSection from "@components/homePage/FeaturesSection"
import TestimonialSection from "@components/homePage/TestimonialSection"
import BlueStripe from "@components/common/stripes/BlueStripe"
import PromotionSection from "@components/common/promotionSection"
import SEO from "@components/common/seo/seo"
import InfoSection from "@components/homePage/InfoSection"
import countryContentFilter from "@utils/countryContentFilter"

type Props = {
  data: { home: SanityPage }
  location: string
}

const IndexPage: FC<Props> = ({ data: { home }, location }) => {
  const { lang, countryCode } = React.useContext(LangContext)

  const testimonialsSection = countryContentFilter(
    home,
    countryCode,
    sanity_typename.SanityTestimonialsSection
  )[0] as SanityTestimonialSection

  const carouselSection = countryContentFilter(
    home,
    countryCode,
    sanity_typename.SanityCarouselSection
  )[0] as SanityCarouselSection

  const sanityHero = countryContentFilter(
    home,
    countryCode,
    sanity_typename.SanityHeroSection
  )[0] as SanityHero

  const businessSection = countryContentFilter(
    home,
    countryCode,
    sanity_typename.SanityBusinessSection
  )[0] as SanityBusinessSection

  const promotionSection = countryContentFilter(
    home,
    countryCode,
    sanity_typename.SanityPromotionSection
  ) as SanityPromotion[]

  const featuresSection = countryContentFilter(
    home,
    countryCode,
    sanity_typename.SanityFeaturesSection
  )[0] as SanityFeaturesSection

  const infoSection = countryContentFilter(
    home,
    countryCode,
    sanity_typename.SanityInfoSection
  )[0] as SanityInfoSection

  if (typeof document !== "undefined") {
    var header = document.querySelector("header")
    if (header) {
      header.style.display = "flex"
    }
  }

  return (
    <>
      <SEO title={home?.title[lang]} location={location} />
      <HeroSection sanityHero={sanityHero} businessSection={businessSection} />
      <PromotionSection promotionSection={promotionSection[0]} />
      <PromotionSection
        promotionSection={promotionSection[1]}
        className="m-top"
      />
      <TestimonialSection testimonialsSection={testimonialsSection} />
    </>
  )
}

export default IndexPage

export const query = graphql`
  {
    home: sanityPage(slug: { current: { eq: "/" } }) {
      title {
        en
        ar
      }
      content {
        ...SanityHero
        ...SanityBusiness
        ...SanityTestimonials
        ...SanityPromotion
        ...SanityFeaturesSection
        ...SanityCarouselSection
        ...SanityInfoSection
      }
    }
  }
`
