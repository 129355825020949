import React, { FC } from "react"
import LangContext from "@contexts/lang"
import s from "./RestaurantsSection.module.scss"
import { SanityBusinessSection } from "@utils/types"
import Business from "./Business"


//Components

const RestaurantsSection: FC<{ businessSection: SanityBusinessSection }> = ({
  businessSection,
}) => {
  const { lang } = React.useContext(LangContext)
  const { title, business } = businessSection

  return (
    <div className={s.restaurantsSection}>
      {title && <h2>{title[lang]}</h2>}
      {business.length && (
        <div className={s.imagesContainer}>
          {business.map((busines, i) => (
            <Business busines={busines} key={i} />
          ))}
        </div>
      )}
    </div>
  )
}

export default RestaurantsSection
