import React, { FC } from "react"
/* import { graphql, useStaticQuery } from "gatsby" */
import Img from "gatsby-image"
import LangContext from "@contexts/lang"
import s from "./HeroSection.module.scss"
import FreeTrialForm from "../../common/FreeTrialForm"
import RestaurantsSection from "../RestaurantsSection"
import { SanityBusinessSection, SanityHero } from "@utils/types"
import useMounted from "@hooks/useMounted"

const HeroSection: FC<{
  sanityHero: SanityHero
  businessSection: SanityBusinessSection
}> = ({ sanityHero, businessSection }) => {
  const { lang } = React.useContext(LangContext)
  const hasMounted = useMounted()

  const { title, subtitle, registrationForm, chips, image, video, commission } = sanityHero
  const partialTitle = title[lang].split("|")
  return (
    <>
      <div className={`${s.heroSection} ${lang === "ar" && s.flipped}`}>
        <div className={s.texts}>
          <div className={s.titleWrapper}>
            <div className={s.titleContainer}>
              <h1 className={s.title}>{partialTitle[0]}</h1>
            </div>
          </div>
          <h4 className={s.description}>{subtitle[lang]}<span className={s.commission}>{commission}</span></h4>
          {registrationForm && (
            <FreeTrialForm registrationForm={registrationForm} addLead />
          )}
        </div>
        {hasMounted && image && lang && (
          <div>
            <Img
              className={s.mainImage}
              imgStyle={{ objectFit: "contain" }}
              fluid={image[lang].asset.fluid}
            />
          </div>
        )}
      </div>
      <RestaurantsSection businessSection={businessSection} />
    </>
  )
}

export default HeroSection
