import React from "react"
import Img from "gatsby-image"
import LangContext from "@contexts/lang"
import { SanityBusiness } from "@utils/types"
import { countryByCountryCode } from "@utils/util"
import s from "./RestaurantsSection.module.scss"
import useMounted from "@hooks/useMounted"

const Business: React.FC<{ busines: SanityBusiness }> = ({ busines }) => {
  const hasMounted = useMounted()
  const { lang, countryCode } = React.useContext(LangContext)
  const { image, category, title, url } = busines
  const currentCountry = countryByCountryCode[countryCode]
  const shouldRender = busines?.countries.includes(currentCountry)
  if (shouldRender) {
    return (
      <div className={s.busines}>
        <div className={s.imgWrapper}>
          {hasMounted && image && (
            <a href={url} target="_blank">
              <Img className={s.image} fluid={image?.asset?.fluid} />
            </a>
          )}
        </div>

      </div>
    )
  }
  return <></>
}

export default Business
